import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import AuthContainer from '../components/AuthContainer';
import LabeledInput from '../components/LabeledInput';
import Page from '../components/Page';
import api from '../api';
import history from '../history';
import { nsOptions } from '../i18n';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import isMaxLoginAttemptsExceeded from '../utils/auth';
import { WEBSITE_URL } from '../constants';

@withToastManager
@withTranslation('', nsOptions)
class Recover extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    api.logout();
  }

  getTopInfo() {
    if (this.passwordExpired()) {
      return this.props.t('auth:pages.password-expired.info');
    }
    if (isMaxLoginAttemptsExceeded()) {
      return this.props.t('auth:pages.forgotten-password.too-many-attempts');
    }

    return this.props.t('auth:pages.forgotten-password.info');
  }

  handleFormSubmit = async (event) => {
    event.preventDefault();

    const { emailInput } = this;
    if (emailInput) {
      emailInput.disabled = true;
      try {
        await api.create('send-password-reset-email', { email: emailInput.value });
        Toast.success(this.props, 'error:valid.we-sent-you-a-link', 4000);
        history.push('/');
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
        emailInput.disabled = false;
      }
    }
  };

  passwordExpired() {
    const passwordExpired = new URLSearchParams(history.location.search).get('passwordExpired') || '';
    return passwordExpired === 'true';
  }

  render() {
    const { t } = this.props;
    return (
      <Page
        {...this.props}
        title={t('auth:forgotten-password')}
        checkAuthorizations={[]}
      >
        <AuthContainer
          top={{
            info: this.getTopInfo(),
            addingCssClass: isMaxLoginAttemptsExceeded() || this.passwordExpired() ? 'pt-5 text-red font-weight-normal' : '',
          }}
          bottom={{
            navContent: !isMaxLoginAttemptsExceeded() ? (
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button className="btn btn-newturquoise-1" onClick={this.handleFormSubmit}>
                    {t('auth:button.reset')}
                  </button>
                </div>
                <div className="col-auto">
                  <Link to="/auth/login" className="btn btn-link text-white">
                    {t('common:button.back')}
                  </Link>
                </div>
              </div>
            ) : (
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button className="btn btn-newturquoise-1" onClick={this.handleFormSubmit}>
                    {t('auth:button.reset')}
                  </button>
                </div>
                <div className="col-12">
                  <div className="authentication-small-info">
                    <Link to="/auth/register">
                      {t('auth:button.register-to-doqboard')}
                    </Link>
                    |
                    <a href={WEBSITE_URL} target="_blank" rel="noopener noreferrer">
                      {t('auth:button.visit-website')}
                    </a>
                  </div>
                </div>
              </div>
            ),
          }}
        >
          <div className="authentication-form py-4 px-5">
            <LabeledInput
              type="email"
              name="email"
              label={t('user:email')}
              placeholder={t('error:placeholder.email')}
              validation="email"
              required
              onValidChange={(e) => {
                this.emailInput = e.target;
              }}
            />
          </div>
        </AuthContainer>
      </Page>
    );
  }
}


export default Recover;

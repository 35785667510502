import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nsOptions } from '../i18n';
import NewTooltip from './NewTooltip';
import ErrorUtil from '../utils/ErrorUtil';
import ElementUtil from '../utils/ElementUtil';
import { formatPageTitle } from '../utils/data-util';
import {
  ELEMENT_TYPE_MEASUREMENT, ELEMENT_TYPE_MULTIPLE_CHOICES, ELEMENT_TYPE_UNIQUE_CHOICE,
  LINK_OPERATOR_OR, TARGET_TYPE_ELEMENT, TARGET_TYPE_PAGE,
} from '../constants';

export const LinkedIcon = (props) => (
  <span className={`${props.className ? `${props.className} ` : ''}pl-3 fa-layers fa-fw`}>
    <FontAwesomeIcon icon={[props.iconStyle, 'square']} transform="shrink-5 left-6 up-6" />
    <FontAwesomeIcon
      icon={['fas', 'level-up-alt']}
      transform="rotate-90 shrink-7 left-2 down-3"
    />
    <FontAwesomeIcon icon={[props.iconStyle, 'square']} transform="shrink-5 right-8 down-4" />
  </span>
);

LinkedIcon.propTypes = {
  className: PropTypes.string,
  iconStyle: PropTypes.oneOf(['fas', 'fal', 'far']),
};

LinkedIcon.defaultProps = {
  className: '',
  iconStyle: 'fas',
};


const mapStateToProps = (state, ownProps) => ({
  page: state.projectPages[ownProps.tabId],
  pages: state.projectPages,
  projectElements: state.projectElements,
  elements: state.elements,
  elementModalities: state.elementModalities,
  elementLinks: state.elementLinks,
});


@withToastManager
@connect(mapStateToProps)
@withTranslation('', nsOptions)
class ElementLinksInfo extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    className: PropTypes.string,
    target: PropTypes.shape().isRequired,
    targetType: PropTypes.oneOf([TARGET_TYPE_ELEMENT, TARGET_TYPE_PAGE]).isRequired,
    pages: PropTypes.shape().isRequired,
    projectElements: PropTypes.shape().isRequired,
    elements: PropTypes.shape().isRequired,
    elementModalities: PropTypes.shape().isRequired,
    elementLinks: PropTypes.shape().isRequired,
    projectEntries: PropTypes.shape(),
    isEntryMode: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    projectEntries: {},
    isEntryMode: false,
  };

  constructor(props) {
    super(props);
    this.actions = {
      eq: '=',
      gt: '>',
      lt: '<',
      gteq: '>=',
      lteq: '<=',
    };
    this.mounted = false;
    this.memoizedRenderTooltip = memoize(this.renderTooltip);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  renderLink = (link, pagesList, projectElements, elements, choices) => {
    const { t } = this.props;
    const projectElement = projectElements[link.source];
    const element = projectElement && elements[projectElement.element];
    let isMeasurementLink = false;
    let isChoiceLink = false;

    if (element) {
      isMeasurementLink = element.type === ELEMENT_TYPE_MEASUREMENT;
      isChoiceLink = [
        ELEMENT_TYPE_UNIQUE_CHOICE,
        ELEMENT_TYPE_MULTIPLE_CHOICES,
      ].includes(element.type);
    }

    return (isMeasurementLink || isChoiceLink) && (
      <div key={link.id} className="pl-1">
        -&nbsp;
        {formatPageTitle(pagesList.find((page) => page.id === projectElement.project_page), t)}
        &nbsp;/&nbsp;
        {ElementUtil.formatElementName(elements[projectElement.element], t)}
        {isChoiceLink && (
          <span>
            &nbsp;/&nbsp;
            <span>{choices.find((choice) => choice.id === link.choice).name}</span>
          </span>
        )}
        {isMeasurementLink && (
          <span>
            &nbsp;
            {this.actions[link.action]}
            &nbsp;
            <span>{link.value}</span>
          </span>
        )}
      </div>
    );
  };

  linkConditionIsTrue = (link, element, entries) => {
    if (element.type === ELEMENT_TYPE_MEASUREMENT && entries && entries.length > 0) {
      const valueNumber = parseFloat(link.value);
      switch (link.action) {
        case 'eq':
          return entries[0].value === valueNumber;
        case 'gt':
          return entries[0].value > valueNumber;
        case 'lt':
          return entries[0].value < valueNumber;
        case 'gteq':
          return entries[0].value >= valueNumber;
        case 'lteq':
          return entries[0].value <= valueNumber;
        default:
          return false;
      }
    } else if ([
      ELEMENT_TYPE_UNIQUE_CHOICE,
      ELEMENT_TYPE_MULTIPLE_CHOICES,
    ].includes(element.type) && entries) {
      return entries.filter((entry) => entry.value === link.choice).length > 0;
    }

    return false;
  };

  renderTooltip(pages, target, targetType, projectElements, elements, elementModalities,
    elementLinks, projectEntries, isEntryMode) {
    const { t } = this.props;

    try {
      let links = Object.values(elementLinks).filter((link) => (
        target.id === (targetType === TARGET_TYPE_ELEMENT ? link.element_target : link.page_target)
      ));
      const pElements = Object.values(projectElements).filter((pEl) => (
        links.find((lk) => lk.source === pEl.id)
      ));

      if (isEntryMode && projectEntries && target.link_operator === LINK_OPERATOR_OR) {
        links = links.filter((link) => (
          this.linkConditionIsTrue(
            link,
            elements[pElements.find((pEl) => pEl.id === link.source).element],
            Object.values(projectEntries).filter((entry) => entry.project_element === link.source),
          )
        ));
      }

      const choices = [];
      const pagesIds = new Set([]);

      pElements.forEach((pEl) => {
        const element = elements[pEl.element];
        const { values } = element;

        pagesIds.add(pEl.project_page);

        if (values && Array.isArray(values)) {
          values.forEach((choice) => { choices.push(elementModalities[choice]); });
        }
      });

      const pagesList = Object.values(pages).filter((page) => pagesIds.has(page.id));

      let operatorKey = target.link_operator === LINK_OPERATOR_OR
        ? 'project:modal.link.logic-operator-or-explanation'
        : 'project:modal.link.logic-operator-and-explanation-multiple';

      if (links.length === 1) {
        operatorKey = 'project:modal.link.logic-operator-and-explanation-single';
      }

      if (links.length > 0) {
        const content = (
          <div className="text-light">
            <span>
              {t(operatorKey)}
              &nbsp;:
            </span>
            {links.map((link) => this.renderLink(link, pagesList, projectElements, elements,
              choices))}
          </div>
        );
        return content;
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
    return null;
  }

  render() {
    const {
      className, pages, projectElements, elements, elementModalities, elementLinks,
      target, targetType, projectEntries, isEntryMode,
    } = this.props;
    console.log(this.props);
    const content = this.memoizedRenderTooltip(pages, target, targetType, projectElements,
      elements, elementModalities, elementLinks, projectEntries, isEntryMode);

    return content && (
      <NewTooltip
        content={content}
        placement="bottom"
      >
        <LinkedIcon className={`linked-icon${className ? ` ${className}` : ''}`} />
      </NewTooltip>
    );
  }
}


export default ElementLinksInfo;

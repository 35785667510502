import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import { projectEntriesActions } from '../redux/actions';
import { getSortingHandlerId } from '../redux/actions/sorting-handler';
import { nsOptions } from '../i18n';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import { getPageTitle } from '../utils/data-util';
import ElementBase, { retrieveProjectEntries, getMutex } from './ElementBase';
import ElementModuleModal from './ElementModuleModal';
import ElementModuleModalView from './ElementModuleModalView';
import Help from './Help';
import { ENTRY_TYPE_MODULE } from '../constants';
import { CardLoader } from './Loader';


const mapStateToProps = (state, ownProps) => ({
  element: state.elements[ownProps.elementId],
  inclusion: state.inclusions[ownProps.inclusionId],
  projectElement: state.projectElements[ownProps.projectElementId],
  projectEntries: state.projectEntries,
});

const mapDispatchToProps = (dispatch) => ({
  addEntry: async (data) => dispatch(projectEntriesActions.create(
    { type: ENTRY_TYPE_MODULE, ...data },
  )),
  patchEntry: async (id, data) => dispatch(projectEntriesActions.patch(id, data)),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
class ElementModule extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    elementId: PropTypes.number,
    projectElementId: PropTypes.number,
    tabId: PropTypes.number,
    moduleInstanceId: PropTypes.number,
    inclusion: PropTypes.shape(),
    inclusionId: PropTypes.number,
    parent: PropTypes.shape({
      content: PropTypes.shape(),
    }),
    methods: PropTypes.shape({
      move: PropTypes.func,
      reload: PropTypes.func.isRequired,
      remove: PropTypes.func,
    }).isRequired,
    isEditMode: PropTypes.bool,
    isEntryMode: PropTypes.bool,
    admin: PropTypes.bool,
    isModule: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    project: PropTypes.shape().isRequired,
    element: PropTypes.shape().isRequired,
    projectEntries: PropTypes.shape(),
    projectElement: PropTypes.shape().isRequired,
    rootProjectElements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    patchEntry: PropTypes.func.isRequired,
    addEntry: PropTypes.func.isRequired,
  };

  static defaultProps = {
    elementId: null,
    projectElementId: null,
    tabId: null,
    moduleInstanceId: null,
    inclusion: null,
    inclusionId: null,
    parent: null,
    isEditMode: false,
    isEntryMode: false,
    admin: false,
    isModule: false,
    isReadOnly: false,
    projectEntries: null,
  };

  constructor(props) {
    super(props);
    this.instanceRefs = {};
    this.getPEntries = memoize((
      projectEntries,
      inclusionId,
      projectElementId,
      moduleInstanceId,
    ) => (
      retrieveProjectEntries(
        Object.values(projectEntries || {}), inclusionId, projectElementId, moduleInstanceId,
      )
    ));
    this.state = {
      loading: false,
    };
  }

  checkLinks = () => {
    if (this.elBaseRef) this.elBaseRef.checkLinks();
  };

  checkForCorruptedMissingDataCount = async () => {
    if (this.elBaseRef) this.elBaseRef.checkForCorruptedMissingDataCount();
  }

  addInstance = async () => {
    const mutex = getMutex(this.props);
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      this.setState({ loading: true });
      try {
        const {
          addEntry, inclusionId, projectElement,
        } = this.props;
        const instance = await addEntry({
          inclusion: inclusionId,
          project_element: projectElement.id,
        });
        const modal = this.instanceRefs[instance.id];
        if (modal) modal.show();
        this.checkForCorruptedMissingDataCount();
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        release();
        this.setState({ loading: false });
      }
    }
  };

  renameInstance = async (id, value) => {
    try {
      await this.props.patchEntry(id, { value });
      Toast.success(this.props, 'error:valid.saved');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  onSwitchChange = async (e) => {
    if (this.elBaseRef) {
      const { patchElement } = this.elBaseRef;
      const value = e.target.checked || null;
      try {
        await patchElement({ can_rename_entry: value });
        Toast.success(this.props, 'error:valid.saved');
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      }
    }
  };

  render() {
    const {
      t, isEntryMode, isEditMode, isReadOnly, projectEntries: pEntries, project, tabId, element,
      projectElement, inclusionId, inclusion, parent, rootProjectElements, projectElementId,
      moduleInstanceId,
    } = this.props;
    const projectEntries = this.getPEntries(
      pEntries, inclusionId, projectElementId, moduleInstanceId,
    );
    const options = {};

    if (isEditMode && !isEntryMode) {
      options.disabled = true;
      if (this.input) this.input.value = '';
    }

    const tabName = getPageTitle(project, tabId, t);

    return (
      <ElementBase
        {...this.props}
        elementCategory="entry"
        subClassName="align-items-center mb-2"
        ref={(ref) => { this.elBaseRef = ref; }}
      >
        <div className="col-auto module-instance-list">
          {this.state.loading && <CardLoader className="position-fixed" />}
          {
            isEntryMode && !isEditMode ? (
              <button
                className="btn btn-lg btn-outline-primary mr-2 mb-2"
                onClick={this.addInstance}
                disabled={isReadOnly}
              >
                {t('project:form.add-an-instance')}
              </button>
            ) : null
              }
          {
            isEntryMode && projectEntries
              ? projectEntries.map((instance) => (
                <ElementModuleModalView
                  key={instance.id}
                  module={element}
                  projectModule={projectElement}
                  inclusion={inclusion}
                  project={parent}
                  rootProjectElements={rootProjectElements}
                  tabName={tabName}
                  moduleInstanceId={instance.id}
                  instanceName={instance.value}
                  renameInstance={(value) => this.renameInstance(instance.id, value)}
                  checkForCorruptedMissingDataCount={this.checkForCorruptedMissingDataCount}
                  ref={(modal) => {
                    this.instanceRefs[instance.id] = modal;
                  }}
                >
                  <button
                    className="btn btn-lg btn-newturquoise-2 mr-2 mb-2"
                  >
                    {instance.value}
                  </button>
                </ElementModuleModalView>
              )) : null
          }
          {
            isEditMode || (isReadOnly && !isEntryMode) ? (
              <ElementModuleModal
                {...this.props}
                module={element}
                projectModule={projectElement}
                formSortingHandlerId={getSortingHandlerId('module', projectElement.id)}
                isEditMode={isEditMode}
                isReadOnly={isReadOnly}
              >
                {isEditMode ? (
                  <button className="btn btn-lg btn-primary">
                    {t('project:form.edit-this-module')}
                  </button>
                ) : (
                  <button className="btn btn-lg btn-outline-gray">
                    {t('project:form.module-preview')}
                  </button>
                )}
              </ElementModuleModal>
            ) : null
          }
        </div>
        {
          isEditMode ? (
            <div className="col-auto px-0">
              <Help
                transform="grow-2"
              >
                {t('common:elements.help.module')}
              </Help>
            </div>
          ) : null
        }
        {
          (isEditMode || (!isEntryMode && isReadOnly)) ? (
            <div className="custom-control custom-switch ml-3 mt-2 col-12">
              <input
                className="custom-control-input display-as-dropdown-input"
                type="checkbox"
                id={`customSwitch-${element.id}`}
                checked={element.can_rename_entry || false}
                onChange={this.onSwitchChange}
                disabled={isReadOnly}
              />
              <label
                className="custom-control-label font-italic display-as-dropdown-label"
                htmlFor={`customSwitch-${element.id}`}
                style={{ paddingLeft: '0.2rem', paddingTop: '0.05rem' }}
              >
                <div className="container">
                  <div className="row align-items-center display-as-dropdown-text">
                    {t('project:form.can-rename-entry')}
                    <Help
                      iconClassName="ml-1 mt-1"
                      iconColor={isReadOnly ? 'text-gray' : 'text-dark-light'}
                    >
                      {t('project:form.can-rename-entry-help')}
                    </Help>
                  </div>
                </div>
              </label>
            </div>
          ) : null
        }
      </ElementBase>
    );
  }
}


export default ElementModule;
